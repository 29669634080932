.l-table-wrapper--live-sport-order .l-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000005d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.l-table-wrapper--live-sport-order .l-pagination {
  display: flex;
  justify-content: center;
}
.l-table-wrapper--live-sport-order .c-table {
  display: table;
  border-left: 1px solid #adb0b2;
  border-right: 1px solid #adb0b2;
  background-color: #fff;
}
.l-table-wrapper--live-sport-order .c-table__row {
  display: flex;
  display: table-row;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-arrow-icon {
  font-size: 12px;
  border: transparent;
  border-radius: 4px;
  padding: 4px;
  outline: none;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-arrow-icon:hover {
  opacity: 0.8;
  outline: none;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-arrow-icon:active {
  opacity: 0.7;
  outline: none;
}
.l-table-wrapper--live-sport-order .c-table__row:nth-child(even) {
  background-color: #fafafa;
}
.l-table-wrapper--live-sport-order .c-table__row-item,
.l-table-wrapper--live-sport-order .c-table__row-item--each-hover {
  display: table-cell;
  padding: 0.7rem;
}
.l-table-wrapper--live-sport-order .c-table__row-item:first-child,
.l-table-wrapper--live-sport-order .c-table__row-item--each-hover:first-child {
  width: 130px;
}
.l-table-wrapper--live-sport-order .c-table__body,
.l-table-wrapper--live-sport-order .c-table__head {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.l-table-wrapper--live-sport-order .c-table__head {
  background-color: #606060;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.l-table-wrapper--live-sport-order
  .c-table__head
  .c-table__row-item:not(:first-child) {
  box-shadow: inset 1px 0 0 #fff;
}
.l-table-wrapper--live-sport-order .c-table__body .c-table__row-item,
.l-table-wrapper--live-sport-order .c-table__body .c-table__row-item--each-hover {
  border-bottom: 1px solid #adb0b2;
}
.l-table-wrapper--live-sport-order .c-table__body .c-table__row-item:first-child,
.l-table-wrapper--live-sport-order .c-table__body .c-table__row-item--each-hover:first-child {
  border-bottom: 1px solid #adb0b2;
  width: 130px;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .three {
  border: 1px solid grey;
  display: inline-block;
  border: 2px solid #7f807f;
  border-radius: 3px;
  margin: 3px;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .three:first-child {
  width: 70%;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .three:nth-child(2) {
  width: 40%;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .three:first-child,
.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .three:nth-child(2),
.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .three:nth-child(3) {
  padding-left: 4px;
  font-size: 14px;
}
.l-table-wrapper--live-sport-order
  .c-table__body
  .c-table__row-item:not(:first-child),
.l-table-wrapper--live-sport-order
  .c-table__body
  .c-table__row-item--each-hover:not(:first-child) {
  box-shadow: inset 1px 0 0 #adb0b2;
}
.l-table-wrapper--live-sport-order .c-table__row-actions {
  font-size: 24px;
}
.l-table-wrapper--live-sport-order .c-table__row-wrap {
  max-height: 300px;
  overflow: auto;
}
.l-table-wrapper--live-sport-order .c-table__row-wrap > .c-dropdown {
  position: sticky;
  top: 0;
  z-index: 5;
}
.l-table-wrapper--live-sport-order .c-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  text-align: center;
  background-color: #b4b4b4;
  font-weight: bold;
  color: #2d2d2d;
  border-radius: 3px;
  margin-bottom: 4px;
  transition: opacity 0.3s ease-in-out;
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--add {
  background-color: #85b733;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--add:active {
  opacity: 0.8;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--edit {
  border: 2px solid #7f807f;
  background-color: #e1e1e1;
  justify-content: stretch;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--edit:hover {
  opacity: 0.9;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--edit .c-tag__add {
  transform: translate3d(-30px, 0, 0);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--edit .c-tag__remove {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--edit .c-tag__input {
  pointer-events: all;
  width: calc(100% - 64px);
  padding-left: 64px;
}
.l-table-wrapper--live-sport-order .c-tag.c-tag--table-hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.l-table-wrapper--live-sport-order .c-tag:hover .c-tag__remove {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.l-table-wrapper--live-sport-order .c-tag:hover .c-tag__edit {
  opacity: 1;
  transform: translate3d(-32px, 0, 0);
}
.l-table-wrapper--live-sport-order .c-tag__remove {
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translate3d(3rem, 0, 0);
  background-color: #d9534f;
  width: 32px;
  height: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #ffffff;
}
.l-table-wrapper--live-sport-order .c-tag__remove:hover {
  background-color: #bb3531;
}
.l-table-wrapper--live-sport-order .c-tag__edit {
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translate3d(3rem, 0, 0);
  background-color: #797979;
  width: 32px;
  height: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #ffffff;
}
.l-table-wrapper--live-sport-order .c-tag__edit:hover {
  background-color: #5b5b5b;
  color: #ffce17;
}
.l-table-wrapper--live-sport-order .c-tag__input {
  padding-left: 18px;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: center;
  pointer-events: none;
}
.l-table-wrapper--live-sport-order .c-tag__add {
  position: absolute;
  right: 0;
  background-color: #85b733;
  width: 32px;
  height: 100%;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #ffffff;
}
.l-table-wrapper--live-sport-order .c-tag__add:hover {
  background-color: #679915;
  color: inherit;
}
.l-table-wrapper--live-sport-order .c-icon-btn {
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.l-table-wrapper--live-sport-order .c-icon-btn:hover {
  opacity: 0.8;
}
.l-table-wrapper--live-sport-order .c-popup {
  background-color: #ffffff;
  padding: 2rem 1rem 1rem;
  border-radius: 4px;
}
.l-table-wrapper--live-sport-order .c-popup__head {
  margin-bottom: 2rem;
  font-weight: bold;
}
.l-table-wrapper--live-sport-order .c-popup__actions {
  display: flex;
  justify-content: flex-end;
}
.l-table-wrapper--live-sport-order .c-select {
  background: #6a6a6a url(../../assets/arrow-select.svg) no-repeat;
  background-position: right 10px center;
  height: 35px;
  padding: 0 12px;
  color: #ffce17;
  appearance: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  outline: none;
  border-radius: 3px;
}
.l-table-wrapper--live-sport-order .u-text-bold {
  font-weight: bold;
}
.l-table-wrapper--live-sport-order .u-v-align-middle {
  vertical-align: middle;
}
.l-table-wrapper--live-sport-order .u-text-center {
  text-align: center;
}
.l-table-wrapper--live-sport-order .u-v-align-top {
  vertical-align: top;
}
.l-table-wrapper--live-sport-order .u-text-right {
  text-align: right;
}
.l-table-wrapper--live-sport-order .u-text-danger {
  color: #d9534f;
}
.l-table-wrapper--live-sport-order .u-ml-md {
  margin-left: 0.5rem;
}
.l-table-wrapper--live-sport-order .u-mb-md {
  margin-bottom: 0.5rem;
}
.l-table-wrapper--live-sport-order .u-w-120 {
  width: 35px;
}
.l-table-wrapper--live-sport-order .u-font-lg {
  font-size: 20px;
}
.c-snackbar {
  position: fixed;
  width: 520px;
  bottom: 20px;
  transform: translate(-50%, 0%);
  left: calc(50% + 125px);
  z-index: 20;
}
.c-snackbar__message {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #85b733;
  color: white;
  padding: 10px;
  border-radius: 4px;
}

.c-snackbar__message--error {
  background-color: #d9534f;
}
.c-snackbar__message:not(:last-child) {
  margin-bottom: 5px;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations {
  border: 2px solid #7f807f;
  background-color: #ebebeb;
  justify-content: flex-start;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--transparent {
  background-color: transparent;
  border: none;
}

.l-table-wrapper--live-sport-order .c-table__row-item:hover .c-tag--add {
  opacity: 1;
  pointer-events: all;
}

.l-table-wrapper--live-sport-order .c-table__row-item--each-hover .c-table__game-order-wrap {
  margin-bottom: 8px;
  border: 1px solid #a7a7a7;
  border-radius: 3px;
  padding: 6px;
  background-color: #e6e6e6;
}

.l-table-wrapper--live-sport-order .c-table__row-item--each-hover .c-table__game-order-wrap:hover .c-tag--add {
  opacity: 1;
  pointer-events: all;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations:hover {
  opacity: 0.9;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .c-tag__add {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations .c-tag__input {
  pointer-events: all;
  width: 100%;
  padding-left: 12px;
  text-align: left;
  text-overflow: ellipsis;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations.is-editing {
  background-color: white;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations.is-editing .c-tag__add {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.l-table-wrapper--live-sport-order .c-tag.c-tag--translations.is-editing  .c-tag__input {
  width: calc(100% - 32px);
}

.l-table-wrapper--live-sport-order .c-table__row .c-table__row-remove-selection {
  color: #d9534f;
  margin-bottom: 2px;
  font-size: 12px;
  border: transparent;
  border-radius: 4px;
  outline: none;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-remove-selection:hover {
  opacity: 0.8;
  outline: none;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-remove-selection:active {
  opacity: 0.7;
  color: #ec3f39;
  outline: none;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-title {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  margin-bottom: 5px;
}
.l-table-wrapper--live-sport-order .c-table__row .c-table__row-add-new {
  margin: 0 auto;
  border: none;
  padding: 6px;
  display: flex;
  padding: 5px 15px;
  border-radius: 4px;
}

.l-table-wrapper--live-sport-order .c-table__row .c-table__row-add-new-wrap {
  border-top: 1px solid rgb(219, 216, 216);
  padding-top: 5px;
}