table {
  font-size: 16px;
}

.scroll-on-table {
  height: 305px;
  width: 100%;
  overflow-x: auto;
}

.scroll-on-table .room-name {
  width: 40%;
}

.scroll-on-table .connections {
  width: 20%;
}

.margin-right10 {
  margin-right: 10px !important;
}

.space-after-class {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.ui-datepicker {
  background-color: white;
  min-width: 300px;
  box-shadow: -3px 5px 10px grey;
  padding: 8px;
}

.ui-datepicker-next {
  float: right;
}

.ui-datepicker-calendar {
  width: 100%;
}

.ui-datepicker-calendar th, .ui-datepicker-calendar td {
  text-align: center !important;
}

.ui-datepicker-calendar .ui-state-default {
  color: #434343;
}

.ui-datepicker-title {
  text-align: center;
  padding-bottom: 10px;
}

.modal form {
  display: inline;
}

.margin-none {
  margin: 0;
}

.margin-bottom-none-important {
  margin-bottom: 0 !important;
}

.padding10-right {
  padding-right: 10px;
}

.display-inline-flex {
  display: inline-flex;
}

.display-flex-colum {
  display: flex;
  flex-direction: column;
}

.sortable-custome-list {
  display: flex;
  flex-direction: column;
  max-width: 70rem;
  margin: 2rem auto;
}

.sortable-custome-list > li {
  border: 1px solid #d2e5e1;
  margin: 0px;
  padding: 0.8rem;
  cursor: move;
  margin-bottom: 4px;
  border-radius: 4px;
}

.sortable-custome-list > li:hover {
  background: #f9feff;
}

.padding10 {
  padding: 10px;
}

.padding15 {
  padding: 15px;
}

.margin-bottom15 {
  margin-bottom: 15px;
}

.margin-top15 {
  margin-top: 15px;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-bottom25 {
  margin-bottom: 25px;
}

.margin-left25 {
  margin-left: 25px;
}

td form {
  display: inline-block;
}

.margin-md {
  margin: 3rem;
}

.margin-bottom-md {
  margin: 2rem;
}

.margin-top-sm {
  margin-top: 5px !important;
}

.display-flex-align {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.table-column-min-width tbody tr td {
  min-width: 12rem !important;
}

.align-center {
  text-align: center;
}

.hide-element {
  display: none;
}

.margin-left--lg {
  margin-left: 4rem;
}

.content-edit .btn.btn-success {
  padding: 4px 10px;
}

.pagination {
  text-align: center;
  margin: 2rem;
  width: 100%;
}

.width40 {
  width: 40%;
}

.exception-modal-content {
  display: flex;
  flex-wrap: wrap;
}

.exception-modal-content > div {
  width: 100%;
}

.exception-modal-content > div:last-child {
  margin-top: 20px;
}

.exception-modal-content > div:last-child .col-md-4 {
  padding: 0;
  width: 100%;
}

.translation-exception-input {
  width: 50%;
  background-color: white;
  margin-right: 50px;
}

.translation-exception-p {
  width: 50%;
  margin-top: 5px;
  margin-left: 20px;
}

.key-gray-color {
  background-color: lightgrey;
}

.translation-exception-list-span {
  background-color: lightblue;
  padding: 5px;
  color: white;
  margin-right: 5px;
}

.add-exception-button {
  font-size: 14px;
  margin-left: 55%;
  font-weight: normal;
  cursor: pointer;
}

.input-disabled {
  pointer-events: none;
  opacity: 0.6;
}

form .form-group {
  padding: 10px 0;
}

form .form-group label {
  font-weight: normal;
  margin-right: 10px;
}

form .divider {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

form .scrollable-checkboxes {
  height: 200px;
  padding-right: 10px;
  overflow: scroll;
  border: 1px solid #ddd;
  padding-left: 10px;
}

form ul {
  list-style: none;
  padding: 0;
}

form ul li {
  margin: 10px 0;
}

form .multiple-text .input-group-addon {
  padding: 2px 2px;
  width: 30%;
}

form .multiple-text .input-group-addon input {
  height: 26px;
}

form .thumbnail {
  margin-bottom: 0;
}

.delete-website-form input[type='submit'] {
  float: right;
}

#update-website-form input[type='submit'] {
  float: left;
}

.games-order .form-group {
  position: relative;
}

.games-order .form-group .badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #ccc;
}

.games-order dl {
  word-wrap: break-word;
}

ul.sortable-items.ui-sortable li {
  border: 1px solid gray;
  padding: 1%;
  border-radius: 2%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.06);
}

.game-groups .select-sport {
  display: inline-block;
}

.game-groups .panel {
  margin-top: 15px;
}

#casino-category-games, #casino-game-categories {
  position: relative;
}

#casino-category-games ol, #casino-game-categories ol {
  list-style-position: inside;
  padding-left: 0;
}

#casino-category-games ol.position-container, #casino-game-categories ol.position-container {
  padding: 1px 5px 0 0;
}

#casino-category-games ol.position-container li, #casino-game-categories ol.position-container li {
  margin-bottom: 5px;
}

#casino-category-games .casino-game-group:last-child, #casino-game-categories .casino-game-group:last-child {
  margin-bottom: 10px;
}

#casino-category-games .casino-game label.disabled, #casino-game-categories .casino-game label.disabled {
  opacity: 0.5;
}

#casino-category-games .casino-category-group, #casino-game-categories .casino-category-group {
  margin-top: 5px;
}

#casino-category-games .casino-category, #casino-game-categories .casino-category {
  float: left;
  margin-top: 9px;
}

#casino-category-games .expander-handle, #casino-game-categories .expander-handle {
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.casino-game-thumbnail {
  border: none;
}

.casino-game-thumbnail img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
}

#casino-navigation-tabs a.btn {
  margin-top: 10px;
}

#casino-navigation-tabs .badge {
  float: right;
  margin: 5px 0;
}

#casino-navigation-tabs .type-group {
  margin: 10px 0;
}

.validation-error {
  color: #a94442;
  font-size: 12px;
}

.casino-new-categories-button {
  margin-top: 10px;
}

.categories-modal {
  width: 750px;
  overflow-y: auto;
}

.gallery-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.gallery {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background: #fff;
  border: 1px solid #bbb;
}

.gallery .change-folder:hover {
  background-color: #E0FFFF;
}

.gallery .custome-form {
  display: flex !important;
  justify-content: space-between !important;
}

.gallery .custome-form .margin-top-sm {
  margin-top: 20px;
}

.gallery .cursor-pointer {
  cursor: pointer;
}

.gallery .display-block {
  display: block !important;
}

.gallery .modal-header-title {
  display: flex;
}

.gallery .modal-header-title .btn-pencil {
  margin-top: 30px;
  color: #56a5e9;
  cursor: pointer;
}

.gallery .folder-name {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gallery .folder-name .dropdown-menu .change-folder {
  cursor: pointer;
}

.gallery .folder-name .move-all-images-btn {
  margin: 25px 50px 10px 50px;
}

.gallery .folder-name .move-all-images-btn .list-style-none {
  list-style-type: none;
  padding-left: 10px;
}

.gallery .display-flex {
  padding-top: 15px;
  display: flex;
}

.gallery .update-folder-name {
  margin-left: 20px;
  width: 80%;
  margin-right: 100px;
}

.gallery h2 {
  padding: 5px 10px;
}

.gallery h2 .margin-left-large {
  margin-left: 100px;
}

.gallery h2 .glyphicon-pencil {
  font-size: 14px;
  position: absolute;
  margin-left: 10px;
  margin-top: 30px;
  cursor: pointer;
}

.gallery .tab-content {
  overflow-y: scroll;
}

.gallery .close-gallery {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 32;
  color: #888;
}

.gallery .center-form {
  margin-top: 25%;
}

.gallery .tab-pane {
  overflow: auto;
  max-height: calc(100% - 250px);
  padding: 20px;
}

.gallery .tab-pane .icon-size-large {
  font-size: 40px;
  color: #c2c295;
}

.gallery .tab-pane .gallery-tab-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  background: #eee;
}

.gallery .tab-pane .space-between {
  display: flex !important;
  justify-content: space-between !important;
}

.gallery .tab-pane .upload-photo-form {
  margin: 20px auto;
}

.gallery .tab-pane .delete-gallery-folder {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #aa1a1a;
}

.gallery .tab-pane .card:hover .delete-gallery-folder {
  display: block;
}

.gallery .tab-pane .thumbnail {
  position: relative;
  background-color: #aaa !important;
}

.gallery .tab-pane .thumbnail .thumbnail-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gallery .tab-pane .thumbnail .selected-image-sign {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  background: #337ab7;
  color: #fff;
}

.gallery .tab-pane .thumbnail .delete-gallery-image {
  color: #aa1a1a;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.gallery .tab-pane .thumbnail .folder-dropdown {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: grey;
}

.gallery .tab-pane .thumbnail:hover .delete-gallery-image {
  display: block;
}

.gallery .tab-pane .thumbnail:hover .folder-dropdown {
  display: block;
}

.gallery .tab-pane .active.thumbnail {
  border: 1px solid #337ab7;
}

.gallery .tab-pane .active.thumbnail .selected-image-sign {
  display: block;
}

/*# sourceMappingURL=app.css.map */
